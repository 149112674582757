import "./reset.css";
import "./fonts.css";
import "./app.css";

import * as React from "react";

import {
  Operations as AlertOperations,
  operations as alertOperations,
} from "@app/state/ducks/site-alerts/operations";
import { Button, Header, SiteAlert, Theme } from "@ribit/components";
import { Operations, operations } from "@app/state/ducks/tenant/operations";
import { Route, Routes } from "react-router-dom";
import { Route as RouteType, userRoutes } from "./routes";
import { User, isStudent } from "@app/models/user";
import {
  Operations as UserOperations,
  operations as userOperations,
} from "@app/state/ducks/user/operations";
import { mapOperationsToDispatchProps, toTitleCase } from "@ribit/lib";

import Alert from "@app/models/alert";
import { ErrorGreyScreenOfDeath } from "@app/pages/errorGreyScreenOfDeath";
import { History } from "history";
import { Onboarding } from "@app/containers/onboarding";
import { State } from "@app/state";
import Tenant from "@app/models/tenant";
import { Verification } from "@app/components/verification";
import { connect } from "react-redux";
import { CustomSasFooter } from "@app/components/custom-footer/sas-footer/custom-sas-footer";
import { CustomFooter } from "@app/components/custom-footer/custom-footer";
import { Footer } from "@app/components/footer";
import { HistoryRouter as Router } from "redux-first-history/rr6";

type OwnProps = Record<string, never>;

type DispatchProps = {
  operations?: {
    tenant: Operations;
    user: UserOperations;
    alerts: AlertOperations;
  };
};
type StateProps = {
  history?: History;
  user?: User;
  siteAlerts?: Alert[];
  tenant?: Tenant;
};

type AppState = {
  showFooter: boolean;
  errorOccurred: boolean;
};

type AppProps = StateProps & DispatchProps & OwnProps;

class App extends React.Component<AppProps, AppState> {
  state = {
    showFooter: true,
    errorOccurred: false,
  };

  private get isConfirming(): boolean {
    const { history } = this.props;
    return history && history.location.pathname.startsWith("/account/confirm");
  }

  componentDidMount() {
    const { operations, user, history } = this.props;

    operations.tenant.retrieve();
    if (user.isAuthenticated) {
      operations.user.fetchProfile();
    }

    const showHideFooter = () => {
      const showFooter = !this.props.history.location.pathname.startsWith(
        "/messages",
      );
      this.setState({
        showFooter,
      });
    };
    showHideFooter();

    history.listen(() => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
      showHideFooter();
    });
  }

  componentDidCatch(e: Error) {
    this.setState({
      errorOccurred: true,
    });
    (window as any).Sentry.captureException(e);
  }

  renderRoutes(): React.ReactElement<any>[] {
    const { user } = this.props;
    const routes: RouteType[] = userRoutes(user);
    return routes.map((route: RouteType, index: number) => {
      const { path, component } = route;
      return <Route key={index} path={path} element={component()} />;
    });
  }

  renderHeader(): React.ReactElement<any> {
    const { user, tenant } = this.props;
    const routes: RouteType[] = userRoutes(
      user,
      true,
      tenant ? tenant.isPrimary : false,
    );
    let menuItems: any[] = routes
      .filter((route: RouteType) => route.showInNavigation)
      .map((route: RouteType) => {
        const label: string = route.altName
          ? route.altName
          : toTitleCase(route.name);
        return { label: label, link: route.path };
      });
    if (this.props.history.location.pathname.startsWith("/kiosk")) {
      menuItems = [];
    }
    return (
      <Header
        menuItems={menuItems}
        logo={tenant && tenant.theme ? tenant.theme.assets["logo"] : null}
      />
    );
  }

  renderFooter(): React.ReactElement<any> {
    if (!this.state.showFooter) return null;

    const { tenant } = this.props;

    /* A temporary check to see if the current tenant is SAS and if so, use a custom footer.
     * This will eventually be done via the tenant API endpoint with customUi data */
    if (tenant && tenant.uuid === "73b83401-6eec-4c4c-8707-b7b718db5e68") {
      return <CustomSasFooter />;
    }

    const terms: string = tenant.links ? tenant.links.terms : null;
    const privacy: string = tenant.links ? tenant.links.privacy : null;
    const props: { [s: string]: string } = {};
    if (terms) {
      props.terms = terms;
    }
    if (privacy) {
      props.privacy = privacy;
    }

    if (tenant.customUi && tenant.customUi.footer) {
      return <CustomFooter {...tenant.customUi.footer} />;
    }

    return <Footer {...props} disclaimer={null} />;
  }

  renderOnboarding(): React.ReactElement<any> {
    const { user } = this.props;
    if (!user.isAuthenticated || this.isConfirming) {
      return null;
    }

    return <Onboarding />;
  }

  renderVerification(): React.ReactElement<any> {
    const { user } = this.props;
    if (
      !user.isAuthenticated ||
      user.confirmed ||
      isStudent(user) ||
      this.isConfirming
    ) {
      return null;
    }
    return <Verification user={user} />;
  }

  renderSiteAlerts(): React.ReactElement<any>[] {
    return this.props.siteAlerts.map((alert: Alert, index: number) => {
      const action: React.ReactElement<any> = (
        <Button
          label={alert.actionLabel}
          action={() => {
            this.props.operations.alerts.clearAlerts();
          }}
          style="tertiary"
          size="small"
        />
      );
      return (
        <SiteAlert
          message={alert.message}
          type={alert.type}
          key={index}
          action={action}
        />
      );
    });
  }

  render(): React.ReactElement<any> {
    const { history, tenant } = this.props;
    if (this.state.errorOccurred === true) {
      return (
        <Router history={history}>
          <Theme
            variables={tenant && tenant.theme ? tenant.theme.colors : null}
          >
            <ErrorGreyScreenOfDeath />
          </Theme>
        </Router>
      );
    }
    return (
      <Router history={history}>
        <Theme variables={tenant && tenant.theme ? tenant.theme.colors : null}>
          {this.renderSiteAlerts()}
          {this.renderHeader()}
          <Routes>{this.renderRoutes()}</Routes>
          {this.renderFooter()}
          {this.renderOnboarding()}
          {this.renderVerification()}
        </Theme>
      </Router>
    );
  }
}

const mapDispatchToProps = mapOperationsToDispatchProps({
  tenant: operations,
  user: userOperations,
  alerts: alertOperations,
});

const mapStateToProps = (state: State): StateProps => {
  return {
    history: state.history,
    user: state.user,
    siteAlerts: state.siteAlerts,
    tenant: state.tenant,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
