import * as React from "react";
import styles from "./styles.modules.css";
import * as yup from "yup";

import {
  ActionBar,
  Button,
  Container,
  Content,
  Field,
  Form,
  Heading,
  Input,
  Loading,
  Panel,
  Text,
} from "@ribit/components";
import {
  Operations,
  operations,
} from "@app/state/ducks/reset-forgotten-password/operations";
import { RouteComponentProps } from "@app/types";
import { withRouter } from "@app/helpers/hooks";

import { State } from "@app/state";
import { Title } from "@app/components/title";
import { connect } from "react-redux";
import { mapOperationsToDispatchProps } from "@ribit/lib";

type OwnProps = Record<string, never>;
type DispatchProps = {
  operations?: Operations;
};
type StateProps = {
  loading?: boolean;
  valid?: boolean;
  success?: boolean;
  name?: string;
  email?: string;
};
type ResetPasswordProps = RouteComponentProps &
  StateProps &
  DispatchProps &
  OwnProps;

class PureResetPassword extends React.Component<ResetPasswordProps> {
  private get uuid(): string {
    return this.props.match.params["uuid"];
  }

  private get validating(): boolean {
    const { loading, valid } = this.props;
    return loading && !valid;
  }

  private get resetting(): boolean {
    const { loading, valid } = this.props;
    return loading && valid;
  }

  componentDidMount() {
    const { operations } = this.props;
    operations.validate(this.uuid);
  }

  renderLoading() {
    if (!this.validating && !this.resetting) {
      return null;
    }
    return <Loading label="Validating reset token" style="inverted" />;
  }

  renderInvalidToken() {
    if (!this.validating && !this.resetting && !this.props.valid) {
      return (
        <Container>
          <Panel className={styles.message}>
            <Heading text="Error" className={styles.pageTitle} />
            <Text>
              This reset token is invalid, please make sure you have clicked the
              link in your email.
            </Text>
            <ActionBar layout="center" className={styles.action}>
              <Button
                label="Forgot your password"
                action="/auth/forgotten-password"
                style="secondary"
                layout="full-width"
              />
            </ActionBar>
          </Panel>
        </Container>
      );
    }

    return null;
  }

  renderSuccess() {
    if (!this.validating && !this.resetting && this.props.success) {
      return (
        <Container>
          <Panel className={styles.message}>
            <Heading text="Success!" className={styles.pageTitle} />
            <Text>
              Your password has been successfully reset, please login below.
            </Text>
            <ActionBar layout="center" className={styles.action}>
              <Button
                label="Login"
                action="/auth/login"
                style="secondary"
                layout="full-width"
              />
            </ActionBar>
          </Panel>
        </Container>
      );
    }

    return null;
  }

  renderForm = (): React.ReactElement<any> => {
    if (this.validating || !this.props.valid || this.props.success) {
      return null;
    }
    const { operations, name } = this.props;
    return (
      <Container grid="span-medium">
        <div className={styles.copy}>
          <Heading text="Set a new password" className={styles.pageTitle} />
          <Text className={styles.reasons}>
            {name}, you may now change your password to something more
            memorable.
          </Text>
        </div>
        <div>
          <Panel>
            <Heading className={styles.title} text="Change password" />
            <Form
              validateSchema={yup.object().shape({
                new: yup.string().required("New password is a required field"),
                confirm: yup
                  .string()
                  .oneOf([yup.ref("new")], "Passwords must match")
                  .required("Confirm password is a required field"),
              })}
              onSubmit={values => {
                return operations.reset(this.uuid, values.new, values.confirm);
              }}
            >
              <Field required>
                <Input type="password" name="new" />
              </Field>
              <Field required>
                <Input type="password" name="confirm" />
              </Field>
              <ActionBar>
                <Button
                  type="submit"
                  label="Set new password"
                  style="secondary"
                  layout="full-width"
                />
              </ActionBar>
            </Form>
          </Panel>
        </div>
      </Container>
    );
  };

  render(): React.ReactElement<any> {
    return (
      <Content className={styles.outer}>
        <Title label="Reset your password" />
        {this.renderLoading()}
        {this.renderInvalidToken()}
        {this.renderSuccess()}
        {this.renderForm()}
      </Content>
    );
  }
}

const mapStateToProps = (state: State): StateProps => {
  const { valid, name, email, success } = state.resetForgottenPassword.data;
  return {
    loading: state.resetForgottenPassword.loading,
    valid: valid,
    name: name,
    email: email,
    success: success,
  };
};

const ConnectedResetPassword = connect(
  mapStateToProps,
  mapOperationsToDispatchProps(operations),
)(PureResetPassword);
const ResetPassword = withRouter(ConnectedResetPassword);

export { ResetPassword };
export default ResetPassword;
